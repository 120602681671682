const Usersnap = {};

Usersnap.getConfig = apiKey => {
  /* istanbul ignore next */
  // {
  // messages: {
  //   en: {
  //     'rating.label': 'How did you like our NL?',
  //     'question.label': 'Why specifically?',
  //   },
  //   de: {
  //     'rating.label': 'Wie hat dir unser Newsletter gefallen?',
  //     'question.label': 'Was genau?',
  //   }
  // },
  // rater: 'ONE_TO_FIVE',
  // locale: 'en',
  // showEmail: false,
  // showTerms: true,
  // termsUrl: 'http://usersnap.com',
  // backgroundColor: 'red',
  // primaryColor: 'yellow',
  // }

  return new Promise((resolve, reject) => {
    window.onUsersnapCXLoad = api => {
      clearAlert();
      let apiConfig = api.getConfig();
      let remoteConfig = api.evaluateTemplates(apiConfig);
      let config = {};

      /* *********************************************************
       * !!! Temporary workaround
       *
       * Since there is a bug in config I set it manually
       *
       ***********************************************************/

      // let params;
      // params = {
      //   rater: 'test-rater',
      //   locale: 'de-AT',
      //   backgroundColor: 'test-bg',
      //   primaryColor: 'test-color',
      //   showEmail: false,
      //   translations: {
      //     en: {
      //       'question.label': 'Rating'
      //     },
      //     de-AT: {
      //       "email.label": "Email Adresse",
      //       "email.placeholder": "max@mustermann.de",
      //       "error.noapi.body": "Diese Seite kann im Moment kein Feedback annehmen.",
      //       "error.noapi.title": "Kein Feedback Formular verfügbar",
      //       "error.send.body": "Bitte probieren Sie es später noch mal.",
      //       "error.send.title": "Das Feedback konnte nicht gesendet werden",
      //       "form.loading": "Nur noch wenige Sekunden. Wir bereiten das Formular vor...",
      //       "next.label": "Weiter",
      //       "question.label": "Warum?",
      //       "question.placeholder": "Bitte teilen Sie uns mit warum!",
      //       "rating.label": "Wie bewerten Sie uns?",
      //       "submit.label": "Feedback abschicken",
      //       "submit.sending": "Sende die Daten...",
      //       "success.message": "Danke, dass sie sich Zeit genommen haben uns Feedback zu geben.",
      //       "success.title": "Abgeschickt!",
      //       "terms.error": "Bitte stimmen Sie den Nutzungsbedingungen zu.",
      //       "terms.label": "Ich stimme den {link} zu.",
      //       "terms.link": "Nutzungbedingen"          }
      //   },
      //   showTerms: true,
      //   termsUrl: 'http://usersnap.com',
      // };
      // params = {
      //   locale: 'de',
      //   primaryColor: '#3a21ce',
      //   ratingId: 'rating',
      //   ratingLabel: {
      //     detest: 'Wie war Deine Erfahrung mit uns??',
      //     en: 'How was your experience??'
      //   },
      //   commentId: 'comment',
      //   commentLabel: {
      //     detest: 'Warum denn??',
      //     en: 'And why??'
      //   },
      //   commentPlaceholder: {
      //     detest: 'Was könnte wir besser machen?',
      //     en: 'What could we improve?'
      //   },
      //   visitorId: 'visitor',
      //   showTerms: true, // not supported
      //   termsUrl: 'http://usersnap.com', // not supported
      //   nextApi: '' // not supported
      // };

      // if (apiConfig['@template']) {
      //   // apiConfig['@template'].params = params;
      // }

      if (apiConfig.references && apiConfig.references.metrics) {
        if (apiConfig.references.metrics.rating) {
          config.rater = apiConfig.references.metrics.rating.metricType;
          config.raterStyle = apiConfig.references.metrics.rating.displayType;
        }
        if (apiConfig.references.metrics.visitor) {
          config.showEmail =
            apiConfig.references.metrics.visitor.displayType !== 'HIDDEN';
        }
      }
      if (apiConfig && apiConfig.hasOwnProperty('@template')) {
        let params = apiConfig['@template'].params;
        if (params.primaryColor && typeof params.primaryColor == 'string') {
          config.primaryColor = params.primaryColor;
        }

        function setParam(id, mappedId) {
          if (!mappedId) {
            mappedId = id;
          }
          if (params[id]) {
            config[mappedId] = params[id];
          }
        }
        setParam('locale');
        if (!config.locale) {
          config.locale = 'en';
        }
        setParam('ratingId');
        setParam('commentId', 'answerId');
        setParam('visitorId', 'emailId');

        /* Conversion
         * ratingLabel: 'Wie war Deine Erfahrung mit uns?'
         * or
         * ratingLabel: {
         *   de: 'Wie war Deine Erfahrung mit uns?',
         *   en: 'How was your experience?'
         * },
         *
         * de: {
         *   stars.label: 'Wie war Deine Erfahrung mit uns?'
         * },
         * en: {
         *   stars.label: 'How was your experience?'
         * },
         */

        function setLocale(locale, LabelId, label) {
          if (!config.translations[locale]) {
            config.translations[locale] = {};
          }
          config.translations[locale][LabelId] = label;
        }

        function transformLabel(LabelId, label, defaultLocale) {
          if (typeof label === 'string' && config.locale) {
            // fallback locale
            setLocale(defaultLocale, LabelId, label);
          }
          if (typeof label === 'object') {
            Object.keys(label).forEach(function(locale) {
              setLocale(locale, LabelId, label[locale]);
            });
          }
        }

        config.translations = params.translations || {};
        transformLabel('rating.label', params.ratingLabel, config.locale);
        transformLabel('question.label', params.commentLabel, config.locale);
        transformLabel(
          'question.placeholder',
          params.commentPlaceholder,
          config.locale
        );
      }

      if (remoteConfig) {
        // thank you message
      }
      resolve(config);
    };

    var script = window.document.createElement('script');
    script.async = 1;
    script.src =
      'https://widget.usersnap.com/load/' +
      apiKey +
      '?onload=onUsersnapCXLoad&isdev=true';
    document.getElementsByTagName('head')[0].appendChild(script);

    script.onerror = () => {
      let errorMsg = 'Error loading Usersnap ' + script.src;
      /* istanbul ignore next */
      // console.error(errorMsg);
      reject(errorMsg);
    };

    let timeoutID = window.setTimeout(
      reject,
      10 * 1000,
      'Timeout (10s) error loading Usersnap configuration from ' + script.src
    );

    function clearAlert() {
      window.clearTimeout(timeoutID);
    }

    /*  
  return new Promise((resolve, reject) => {
    let config;
    switch (apiKey) {
      case '8116d587-7f1a-40d4-952b-af03fe0a0658':
        config = {
          messages: {
            en: {
              'rating.label': 'How did we live our value: Make Feedback Matter?',
              'question.label': 'What could we done better?'
            }
          },
          showEmail: false,
          nextApi: 'ec76e4ea-dd89-433a-9b95-c8975bf2e31b'
        };
        break;
      case 'ec76e4ea-dd89-433a-9b95-c8975bf2e31b':
        config = {
          messages: {
            en: {
              'rating.label':
                "How did we live our value: It's all about the team?",
              'question.label': 'What could we done better?'
            }
          },
          showEmail: false,
          nextApi: 'e8b6ef5a-fde0-41e1-8f4a-373e5aa3cce5'
        };
        break;
      case 'e8b6ef5a-fde0-41e1-8f4a-373e5aa3cce5':
        config = {
          messages: {
            en: {
              'rating.label': 'How did we live our value: Focus on results?',
              'question.label': 'What could we done better?'
            }
          },
          showEmail: false,
          nextApi: 'f8212eed-7eca-46be-8876-a058eb98bd6f'
        };
        break;
      case 'f8212eed-7eca-46be-8876-a058eb98bd6f':
        config = {
          messages: {
            en: {
              'rating.label': 'How did we live our value: Add Value?',
              'question.label': 'What could we done better?'
            }
          },
          showEmail: false,
          nextApi: 'dd41d8cc-6bfd-44e2-8546-4e4682761358'
        };
        break;
      case 'dd41d8cc-6bfd-44e2-8546-4e4682761358':
        config = {
          messages: {
            en: {
              'rating.label': 'How did we live our value: Open Communication?',
              'question.label': 'What could we done better?'
            }
          },
          showEmail: false
        };
        break;
      default:
        config = {};
    }
    resolve(config);
  */
  });
};

Usersnap.sendFeedback = request => {
  return fetch('https://widget.usersnap.com/api/widget/xhrrpc', {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify({
      type: 'rpc',
      method: 'submit_feedback',
      params: request
    })
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === true) {
        return response;
      }
      return Promise.reject('Could not send feedback.');
    });
};

/* eslint-disable no-undef */
/* istanbul ignore next */
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = Usersnap;
} else {
  if (typeof define === 'function' && define.amd) {
    define([], function() {
      return Usersnap;
    });
  } else {
    window.Usersnap = Usersnap;
  }
}
