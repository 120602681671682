import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FormattedMessage } from 'react-intl.macro';
import './Submit.css';

class Submit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
    this.toggleHover = this.toggleHover.bind(this);
  }

  toggleHover() {
    this.setState({
      hover: !this.state.hover
    });
  }

  render() {
    let style;

    const sending = (
      <div>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <FormattedMessage id="submit.sending" defaultMessage="Sending..." />
      </div>
    );

    if (this.state.hover) {
      style = {
        backgroundColor: this.props.color,
        borderColor: this.props.color
      };
    } else {
      style = {
        backgroundColor: this.props.color,
        borderColor: this.props.color
      };
    }
    return (
      <Button
        className={'submit-feedback'}
        style={style}
        variant="primary"
        onClick={this.props.onClick}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
      >
        {this.props.sending ? sending : this.props.label}
      </Button>
    );
  }
}

Submit.propTypes = {
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Submit;
