import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { FormattedMessage } from 'react-intl.macro';
import Header from './components/Header';
import Noapi from './components/Noapi';
import Error from './components/Error';
import Success from './components/Success';
import StarRater from './components/StarRater';
import ThumbRater from './components/ThumbRater';
import SmileRater from './components/SmileRater';
import NpsRater from './components/NpsRater';
import Question from './components/Question';
import Checkbox from './components/Checkbox';
import Email from './components/Email';
import Submit from './components/Submit';
import appTranslations from './i18n/locales';
import urlParams from './lib/urlParams';
import usersnap from './lib/usersnap';
import './App.css';

// https://formatjs.io/guides/runtime-environments/#polyfill-node
import areIntlLocalesSupported from 'intl-locales-supported';

var localesMyAppSupports = ['en', 'de', 'de-AT'];

if (global.Intl) {
  if (!areIntlLocalesSupported(localesMyAppSupports)) {
    var IntlPolyfill = require('intl');
    Intl.NumberFormat = IntlPolyfill.NumberFormat;
    Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
  }
} else {
  global.Intl = require('intl');
}

const defaults = {
  locale: 'en',
  rater: 'ONE_TO_FIVE',
  raterStyle: 'STARS',
  api: '',
  error: 0,
  rating: 0,
  ratingId: 'rating',
  thumbRating: 0,
  smileRating: 0,
  npsRating: 0,
  showEmail: true,
  showTerms: false,
  acceptTerms: false,
  termsError: false,
  termsUrl: 'https://usersnap.com/terms-of-service',
  emailId: 'visitor',
  email: '',
  answerId: 'comment',
  answer: '',
  messages: {},
  translations: {},
  backgroundColor: '#f8f8f9',
  primaryColor: '#3921CE',
  validApi: false,
  sending: false,
  loading: true,
  submitted: false,
  nextApi: ''
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = defaults;
    this.rate = this.rate.bind(this);
    this.thumbRateUp = this.thumbRateUp.bind(this);
    this.thumbRateDown = this.thumbRateDown.bind(this);
    this.smileRate = this.smileRate.bind(this);
    this.npsRate = this.npsRate.bind(this);
    this.updateAnswer = this.updateAnswer.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
    this.toggleTerms = this.toggleTerms.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
    this.loadNext = this.loadNext.bind(this);
    this.dismissError = this.dismissError.bind(this);
    // this.getMessages = this.getMessages.bind(this);
    this.setLocale = this.setLocale.bind(this);
    this.getConfig = this.getConfig.bind(this);
    this.loadConfig = this.loadConfig.bind(this);
  }

  rate(rating) {
    this.setState({ rating: rating });
  }

  thumbRateUp() {
    this.setState({ thumbRating: 1 });
  }

  thumbRateDown() {
    this.setState({ thumbRating: -1 });
  }

  smileRate(rating) {
    this.setState({ smileRating: rating });
  }

  npsRate(rating) {
    this.setState({ npsRating: rating });
  }

  updateAnswer(answer) {
    this.setState({ answer: answer });
  }

  updateEmail(email) {
    this.setState({ email: email });
  }

  toggleTerms() {
    this.setState({
      acceptTerms: !this.state.acceptTerms,
      termsError: false
    });
  }

  dismissError() {
    this.setState({ error: 0 });
  }

  async loadNext() {
    return this.submitFeedback()
      .then(() => {
        window.history.pushState(
          {},
          'Test Query Param',
          '?api=' + this.state.nextApi
        );
        return this.loadConfig();
      })
      .catch(error => {
        /* istanbul ignore next */
        console.error('Could not move to next form', this.state.nextApi);
      });
  }

  async submitFeedback() {
    if (this.state.showTerms && !this.state.acceptTerms) {
      this.setState({ termsError: true });
      return;
    }
    this.setState({ sending: true });
    try {
      localStorage.setItem('usersnapEmail', this.state.email);
    } catch (error) {
      /* istanbul ignore next */
      console.warn('Could not write localStorage', error);
    }
    let url;
    if (window && window.location) {
      url = window.location.href;
    }
    let screen = {
      width: 0,
      height: 0
    };
    if (window && window.screen) {
      screen = window.screen;
    }
    const request = {
      api_key: this.state.api,
      client: {
        url: url,
        screen_width: screen.width,
        screen_height: screen.height
      }
    };
    request[this.state.answerId] = this.state.answer;
    request[this.state.emailId] = this.state.email;

    switch (this.state.rater) {
      case 'smile':
        request['ratingId'] = this.state.smileRating;
        break;
      case 'THUMB':
        request['ratingId'] = this.state.thumbRating;
        break;
      case 'NPS':
        request['ratingId'] = this.state.npsRating;
        break;
      case 'ONE_TO_FIVE':
      default:
        request['ratingId'] = this.state.rating;
    }

    return usersnap
      .sendFeedback(request)
      .then(jsonResponse => {
        this.setState({
          submitted: true,
          sending: false
        });
      })
      .catch(error => {
        /* istanbul ignore next */
        console.error(error);
        this.setState({
          error: 1,
          sending: false
        });
        throw error;
      });
  }

  // getMessages(configLocale) {
  //   let configTranslations = this.state.translations;
  //   if (configTranslations && configTranslations.hasOwnProperty(configLocale)) {
  //     var messages = Object.assign(
  //       {},
  //       translations[configLocale],
  //       configTranslations[configLocale]
  //     );
  //     return messages;
  //   }
  //   return translations[configLocale];
  // }  if (locale) {}
  setLocale(locale) {
    let messages;
    let translations = this.state.translations;
    if (translations && translations.hasOwnProperty(locale)) {
      messages = Object.assign(
        {},
        appTranslations[locale],
        translations[locale]
      );
    } else {
      messages = appTranslations[locale];
    }
    this.setState({
      locale: locale,
      messages: messages
    });
  }

  async getConfig(api) {
    return usersnap.getConfig(api).then(remoteConfig => {
      let params = urlParams();
      let config = Object.assign({}, remoteConfig, params);
      let locale = config.locale;
      delete config.locale;
      this.setState({
        ...config,
        validApi: true,
        loading: false
      });
      if (locale) {
        this.setLocale(locale);
      }
    });
  }

  async loadConfig() {
    let localEmail;
    try {
      localEmail = localStorage.getItem('usersnapEmail');
    } catch (error) {
      /* istanbul ignore next */
      console.warn('Could not read localStorage', error);
    }
    if (localEmail) {
      this.setState({
        email: localEmail
      });
    }

    let params = urlParams();
    if (!params.api) {
      this.setState({ loading: false });
    } else {
      if (params.locale) {
        this.setLocale(params.locale);
      }
      return this.getConfig(params.api)
        .then(() => {
          this.setState({
            validApi: true,
            loading: false
          });
        })
        .catch(error => {
          /* istanbul ignore next */
          console.error('Could not read remote configuration: ', error);
          this.setState({
            validApi: false,
            loading: false
          });
        });
    }
  }

  componentDidMount() {
    return this.loadConfig();
  }

  render() {
    let noapiComponent;
    let saveErrorComponent;
    let raterComponent;
    let questionComponent;
    let emailComponent;
    let termsComponent;
    let submitComponent;
    let nextComponent;
    let successMessageComponent;

    const appStyle = {
      backgroundColor: this.state.backgroundColor
    };
    const submitColor = this.state.primaryColor;
    const loading = (
      <FormattedMessage
        id="form.loading"
        defaultMessage="Just a few seconds, we are preparing your form..."
      />
    );
    const errorSendTitle = (
      <FormattedMessage
        id="error.send.title"
        defaultMessage="The feedback could not be sent"
      />
    );
    const errorSendMessage = (
      <FormattedMessage
        id="error.send.body"
        defaultMessage="Please try again later."
      />
    );
    const questionLabel = (
      <FormattedMessage id="question.label" defaultMessage="Why?" />
    );
    const questionPlaceholder = (
      <FormattedMessage
        id="question.placeholder"
        defaultMessage="Please let us know why!"
      />
    );
    const emailLabel = (
      <FormattedMessage id="email.label" defaultMessage="Email Address" />
    );
    const emailPlaceholder = (
      <FormattedMessage id="email.placeholder" defaultMessage="joe@mail.com" />
    );
    const raterLabel = (
      <FormattedMessage
        id="rating.label"
        defaultMessage="What is your rating?"
      />
    );
    const termsLabel = (
      <FormattedMessage
        id="terms.label"
        defaultMessage="I accept the {link}."
        values={{
          link: (
            <a href={this.state.termsUrl} target={'_new'}>
              <FormattedMessage id="terms.link" defaultMessage="terms" />
            </a>
          )
        }}
      />
    );
    const termsErrorMessage = (
      <FormattedMessage
        id="terms.error"
        defaultMessage="Please accept the terms."
      />
    );
    const submitLabel = (
      <FormattedMessage id="submit.label" defaultMessage="Submit Feedback" />
    );
    const nextLabel = (
      <FormattedMessage id="next.label" defaultMessage="Next" />
    );

    if (!this.state.validApi) {
      noapiComponent = <Noapi />;
    }

    if (this.state.error) {
      saveErrorComponent = (
        <Error
          title={errorSendTitle}
          message={errorSendMessage}
          handleDismiss={this.dismissError}
        />
      );
    }

    if (this.state.validApi && !this.state.submitted) {
      switch (this.state.rater) {
        case 'THUMB':
          raterComponent = (
            <ThumbRater
              label={raterLabel}
              rating={this.state.thumbRating}
              rateDown={this.thumbRateDown}
              rateUp={this.thumbRateUp}
            />
          );
          break;
        case 'NPS':
          raterComponent = (
            <NpsRater
              label={raterLabel}
              rating={this.state.npsRating}
              rate={this.npsRate}
            />
          );
          break;
        case 'ONE_TO_FIVE':
        default:
          if (this.state.raterStyle === 'SMILEY') {
            raterComponent = (
              <SmileRater
                label={raterLabel}
                rating={this.state.smileRating}
                rate={this.smileRate}
              />
            );
          } else {
            raterComponent = (
              <StarRater
                label={raterLabel}
                rating={this.state.rating}
                rate={this.rate}
              />
            );
          }
      }

      if (this.state.showEmail === true) {
        emailComponent = (
          <Email
            label={emailLabel}
            placeholder={emailPlaceholder}
            email={this.email}
            handleChange={this.updateEmail}
          />
        );
      }

      if (this.state.nextApi) {
        nextComponent = (
          <Submit
            label={nextLabel}
            color={submitColor}
            sending={this.state.sending}
            onClick={this.loadNext}
          />
        );
      } else {
        submitComponent = (
          <Submit
            label={submitLabel}
            color={submitColor}
            sending={this.state.sending}
            onClick={this.submitFeedback}
          />
        );
      }

      if (this.state.showTerms) {
        termsComponent = (
          <Checkbox
            id={'terms'}
            label={termsLabel}
            error={this.state.termsError ? termsErrorMessage : null}
            value={this.state.acceptTerms}
            handleChange={this.toggleTerms}
          />
        );
      }

      if (
        this.state.rating > 0 ||
        this.state.thumbRating !== 0 ||
        this.state.smileRating > 0 ||
        this.state.npsRating > 0
      ) {
        questionComponent = (
          <Row className="justify-content-md-center">
            <Col lg={6}>
              <Form>
                <Question
                  label={questionLabel}
                  placeholder={questionPlaceholder}
                  handleChange={this.updateAnswer}
                />
                {emailComponent}
                {termsComponent}
                {submitComponent}
                {nextComponent}
              </Form>
            </Col>
          </Row>
        );
      }
    }

    if (this.state.submitted) {
      successMessageComponent = (
        <Success
          title={this.state.successTitle}
          message={this.state.successMessage}
        />
      );
    }

    const cache = createIntlCache();
    const intl = createIntl(
      {
        locale: this.state.locale,
        messages: this.state.messages
      },
      cache
    );

    return (
      <RawIntlProvider value={intl}>
        <div style={appStyle} className="App">
          <Container>
            <Header />
            {this.state.loading ? (
              <div className={'loading'}>
                <Spinner animation="grow" />
                {loading}
              </div>
            ) : (
              <div>
                {noapiComponent}
                {saveErrorComponent}
                {raterComponent}
                {questionComponent}
                {successMessageComponent}
              </div>
            )}
          </Container>
        </div>
      </RawIntlProvider>
    );
  }
}

export default App;
