import React, { Component, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormGroup from 'react-bootstrap/FormGroup';
import PropTypes from 'prop-types';
import './NpsRater.css';

class NpsRater extends Component {
  render() {
    const Nps = function Component(props) {
      const [hovered, setHovered] = useState(false);
      const toggleHover = () => setHovered(!hovered);
      const rate = () => props.rate(props.value);
      const className =
        props.rating === props.value || hovered ? ' on' : ' off';
      return (
        <button
          className={'button nps' + className}
          onClick={rate}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
        >
          <b>{props.value}</b>
        </button>
      );
    };

    const npsList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(rating => (
      <Nps
        key={rating}
        rate={this.props.rate}
        rating={this.props.rating}
        value={rating}
      />
    ));

    return (
      <Row className="justify-content-md-center">
        <Col lg={6}>
          <label>{this.props.label}</label>
          <FormGroup>{npsList}</FormGroup>
        </Col>
      </Row>
    );
  }
}

NpsRater.propTypes = {
  label: PropTypes.any,
  rating: PropTypes.number,
  rate: PropTypes.func.isRequired
};

export default NpsRater;
