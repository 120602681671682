import React, { Component, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormGroup from 'react-bootstrap/FormGroup';
import PropTypes from 'prop-types';
import './ThumbRater.css';

class ThumbRater extends Component {
  render() {
    const Thumbs = function Component(props) {
      const [hovered, setHovered] = useState(false);
      const toggleHover = () => setHovered(!hovered);
      const className = 'thumb fa-thumbs-' + props.type;
      return (
        <button
          className={'button'}
          onClick={props.rate}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
        >
          <i
            className={
              hovered || props.selected
                ? className + ' fas'
                : className + ' far'
            }
          ></i>
        </button>
      );
    };
    return (
      <Row className="justify-content-md-center">
        <Col lg={6}>
          <label>{this.props.label}</label>
          <FormGroup>
            <Thumbs
              type={'down'}
              selected={this.props.rating === -1}
              rate={this.props.rateDown}
            />
            <Thumbs
              type={'up'}
              selected={this.props.rating === 1}
              rate={this.props.rateUp}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

ThumbRater.propTypes = {
  label: PropTypes.any,
  rating: PropTypes.number,
  rateUp: PropTypes.func.isRequired,
  rateDown: PropTypes.func.isRequired
};

export default ThumbRater;
