import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import './Error.css';

class Error extends Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  onClose(e) {
    this.props.handleDismiss();
  }

  render() {
    return (
      <Alert variant="danger" onClose={this.onClose} dismissible>
        <Alert.Heading>{this.props.title}</Alert.Heading>
        <p>{this.props.message}</p>
      </Alert>
    );
  }
}

Error.propTypes = {
  title: PropTypes.any,
  message: PropTypes.any,
  handleDismiss: PropTypes.func.isRequired
};

export default Error;
