import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl.macro';

const Success = ({ title, message }) => {
  if (!title) {
    title = <FormattedMessage id="success.title" defaultMessage="Thank you!" />;
  }
  if (!message) {
    message = (
      <FormattedMessage
        id="success.message"
        defaultMessage="We appreciate your feedback."
      />
    );
  }

  return (
    <Row className="justify-content-md-center">
      <Col>
        <h3>{title}</h3>
        <p>{message}</p>
      </Col>
    </Row>
  );
};

export default Success;
