import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '..//assets/images/usersnap-logo.png';
import './Header.css';

const Header = () => (
  <Row className="justify-content-md-center">
    <Col>
      <img src={logo} className="logo" alt="logo" />
    </Col>
    <Col>
      <a className="powered" href="https://usersnap.com" target="new">
        powered by Usersnap
      </a>
    </Col>
  </Row>
);

export default Header;
