import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { injectIntl } from 'react-intl';
import './Email.css';

class Email extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.handleChange(event.target.value);
  }

  render() {
    return (
      <Form.Group controlId="email">
        <Form.Label className={'email'}>{this.props.label}</Form.Label>
        <Form.Control
          type="email"
          onChange={this.handleChange}
          value={this.email}
          // Inline properties need to be Stings
          // In order to keep translation managment
          // working we use FormattedMessage obj anyway
          placeholder={this.props.intl.formatMessage(
            this.props.placeholder.props
          )}
        />
      </Form.Group>
    );
  }
}

Email.propTypes = {
  label: PropTypes.any,
  placeholder: PropTypes.any,
  email: PropTypes.any,
  handleChange: PropTypes.func.isRequired
};

export default injectIntl(Email);
