import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { injectIntl } from 'react-intl';
import './Checkbox.css';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.handleChange();
  }

  render() {
    return (
      <Form.Group controlId={this.props.id}>
        <Form.Check type={'checkbox'}>
          <Form.Check.Input
            isInvalid={!!this.props.error}
            type={'checkbox'}
            onChange={this.handleChange}
            checked={this.props.value}
          />
          <Form.Check.Label className={'checkbox'}>
            {this.props.label}
          </Form.Check.Label>
          {this.props.error && (
            <Form.Control.Feedback type="invalid">
              {this.props.error}
            </Form.Control.Feedback>
          )}
        </Form.Check>
      </Form.Group>
    );
  }
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.any,
  value: PropTypes.any,
  error: PropTypes.any,
  handleChange: PropTypes.func.isRequired
};

export default injectIntl(Checkbox);
