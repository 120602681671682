function urlParams() {
  const params = new URLSearchParams(window.location.search);
  const response = {};

  if (params.has('api') && params.get('api') !== '') {
    response.api = params.get('api');
  }

  if (params.has('lang') && params.get('lang') !== '') {
    response.locale = params.get('lang');
  }

  if (params.has('stars') && params.get('stars')) {
    let ratingParam = parseInt(params.get('stars'), 10);
    if (ratingParam > 0 && ratingParam <= 5) {
      response.rating = ratingParam;
    }
  }

  if (params.has('thumbs') && params.get('thumbs')) {
    let thumbParam = parseInt(params.get('thumbs'), 10);
    if (thumbParam >= -1 && thumbParam <= 1) {
      response.thumbRating = thumbParam;
      response.rater = 'THUMB';
    }
  }

  if (params.has('smile') && params.get('smile')) {
    let ratingParam = parseInt(params.get('smile'), 10);
    if (ratingParam > 0 && ratingParam <= 5) {
      response.smileRating = ratingParam;
      response.rater = 'smile';
    }
  }

  if (params.has('nps') && params.get('nps')) {
    let ratingParam = parseInt(params.get('nps'), 10);
    if (ratingParam > 0 && ratingParam <= 5) {
      response.npsRating = ratingParam;
      response.rater = 'NPS';
    }
  }

  if (params.has('email') && params.get('email')) {
    response.email = params.get('email');
  }

  if (params.has('showEmail') && params.get('showEmail')) {
    const showEmailParam = params.get('showEmail');
    let showEmail = true;
    switch (showEmailParam.toLowerCase()) {
      case 'false':
      case 'no':
      case '0':
      case '':
        showEmail = false;
        break;
      default:
        showEmail = true;
    }
    response.showEmail = showEmail;
  }

  return response;
}

export default urlParams;
