import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Star.css';

class Star extends Component {
  constructor(props) {
    super(props);
    this.handlePreview = this.handlePreview.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleRating = this.handleRating.bind(this);
  }

  handlePreview(e) {
    this.props.handlePreview(this.props.value);
  }

  handleReset(e) {
    this.props.handleReset();
  }

  handleRating(e) {
    this.props.handleRating(this.props.value);
  }

  render() {
    let className = 'star fa-star ';
    if (this.props.value <= this.props.rating) {
      className = className + 'fas';
    } else {
      className = className + 'far';
    }

    return (
      <button
        className={'button'}
        onMouseEnter={this.handlePreview}
        onMouseLeave={this.handleReset}
        onClick={this.handleRating}
      >
        <i className={className}></i>
      </button>
    );
  }
}

Star.propTypes = {
  value: PropTypes.number.isRequired,
  rating: PropTypes.number,
  handlePreview: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleRating: PropTypes.func.isRequired
};

export default Star;
