import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl.macro';
import { injectIntl } from 'react-intl';

const Noapi = () => {
  return (
    <Row className="justify-content-md-center">
      <Col>
        <h3>
          <FormattedMessage
            id="error.noapi.title"
            defaultMessage="No feedback form available"
          />
        </h3>
        <p>
          <FormattedMessage
            id="error.noapi.body"
            defaultMessage="This application is not ready to accept feedback at this time."
          />
        </p>
      </Col>
    </Row>
  );
};

export default injectIntl(Noapi);
