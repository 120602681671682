import React, { Component, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormGroup from 'react-bootstrap/FormGroup';
import PropTypes from 'prop-types';
import './SmileRater.css';

class SmileRater extends Component {
  render() {
    const Smile = function Component(props) {
      const [hovered, setHovered] = useState(false);
      const toggleHover = () => setHovered(!hovered);
      const rate = () => props.rate(props.value);
      const className =
        props.rating === props.value || hovered ? ' fas smile' : ' far smile';
      let smile;
      switch (props.value) {
        case 1:
          smile = <i className={'fa-angry' + className}></i>;
          break;
        case 2:
          smile = <i className={'fa-frown' + className}></i>;
          break;
        case 3:
          smile = <i className={'fa-meh' + className}></i>;
          break;
        case 4:
          smile = <i className={'fa-smile' + className}></i>;
          break;
        case 5:
        default:
          smile = <i className={'fa-grin-hearts' + className}></i>;
          break;
      }
      return (
        <button
          className={'button'}
          onClick={rate}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
        >
          {smile}
        </button>
      );
    };

    const smiles = [1, 2, 3, 4, 5].map(rating => (
      <Smile
        key={rating}
        rate={this.props.rate}
        rating={this.props.rating}
        value={rating}
      />
    ));

    return (
      <Row className="justify-content-md-center">
        <Col lg={6}>
          <label>{this.props.label}</label>
          <FormGroup>{smiles}</FormGroup>
        </Col>
      </Row>
    );
  }
}

SmileRater.propTypes = {
  label: PropTypes.any,
  rating: PropTypes.number,
  rate: PropTypes.func.isRequired
};

export default SmileRater;
