import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormGroup from 'react-bootstrap/FormGroup';
import PropTypes from 'prop-types';
import Star from './Star';

class StarRater extends Component {
  constructor(props) {
    super(props);
    this.previewRating = this.previewRating.bind(this);
    this.resetRating = this.resetRating.bind(this);
    this.rate = this.rate.bind(this);
    this.state = {
      previewRating: 0
    };
  }

  previewRating(rate) {
    this.setState({
      previewRating: rate
    });
  }

  resetRating() {
    this.setState((state, props) => ({
      previewRating: 0
    }));
  }

  rate(rating) {
    this.props.rate(rating);
  }

  render() {
    const stars = [1, 2, 3, 4, 5].map(rating => (
      <Star
        key={rating}
        handlePreview={this.previewRating}
        handleReset={this.resetRating}
        handleRating={this.rate}
        rating={
          this.state.previewRating > 0
            ? this.state.previewRating
            : this.props.rating
        }
        value={rating}
      />
    ));
    return (
      <Row className="justify-content-md-center">
        <Col lg={6}>
          <label>{this.props.label}</label>
          <FormGroup>{stars}</FormGroup>
        </Col>
      </Row>
    );
  }
}

StarRater.propTypes = {
  label: PropTypes.any,
  rating: PropTypes.number,
  rate: PropTypes.func.isRequired
};

export default StarRater;
